<template>
  <div class="item" :class="className">
    <img :src="image" alt="" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class Thumbnail extends Vue {
  @Prop({ required: true })
  image!: string;

  @Prop({ required: false })
  className?: string;
}
</script>

<style scoped lang="scss">
@import '../../../variables';

.item {
  position: absolute;
  img {
    display: block;
    width: 100%;
  }
  &.layer0-0 {
    top: 30%;
    left: 43%;
    width: 480px;
    // filter: blur(0);
  }
  &.layer0-1 {
    top: 30%;
    left: 20%;
    width: 480px;
    // filter: blur(0);
  }
  &.layer1-0 {
    bottom: 18%;
    left: 7%;
    width: 160px;
    // filter: blur(1px);
  }
  &.layer1-1 {
    top: 14.5%;
    left: 40.5%;
    width: 160px;
    // filter: blur(1px);
  }
  &.layer1-2 {
    top: 44.5%;
    right: 19.5%;
    width: 160px;
    // filter: blur(1px);
  }
  &.layer1-3 {
    bottom: 30%;
    right: 2%;
    width: 160px;
    filter: blur(1px);
  }
  &.layer2-0 {
    top: 16.5%;
    left: 5%;
    width: 320px;
    // filter: blur(2px);
  }
  &.layer2-1 {
    bottom: 18%;
    left: 24.5%;
    width: 320px;
    // filter: blur(2px);
  }
  &.layer2-2 {
    top: 14.5%;
    right: 12%;
    width: 320px;
    // filter: blur(2px);
  }
  &.layer2-3 {
    bottom: 11.5%;
    right: 24.5%;
    width: 320px;
    // filter: blur(2px);
  }
  &.layer3-0 {
    top: 40.5%;
    left: 28%;
    width: 80px;
    // filter: blur(3px);
  }
  &.layer3-1 {
    bottom: 9.5%;
    left: 40.5%;
    width: 80px;
    // filter: blur(3px);
  }
}
</style>
