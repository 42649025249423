<template>
  <div class="price">
    <div class="value">{{ price }}</div>
    <div class="currency">{{ currency }}</div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class PricePlan extends Vue {
  @Prop({ required: true })
  value!: string;

  get price() {
    return this.value.split(' ')[0];
  }

  get currency() {
    const value = this.value.split(' ');
    value.shift();
    return value.join(' ');
  }
}
</script>

<style scoped lang="scss">
.price {
  .value {
    display: inline-block;
    font-size: 26px;
    font-weight: 700;
    letter-spacing: 0.19px;
    margin-right: 4px;
  }
  .currency {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.1px;
  }
}
</style>
