import axios from 'axios';
import { BE_BASE_URL, SEQUOIA_BASE_URL, URL } from './constants';

const beInstance = axios.create({
  baseURL: BE_BASE_URL,
  withCredentials: true,
});

const sequoiaInstance = axios.create({
  baseURL: SEQUOIA_BASE_URL,
  withCredentials: true,
});

export interface TTranslation {
  id: string;
  key: string;
  translations: { [key: string]: string };
}

export interface TChannel {
  id: string;
  info: {
    metaInfo: {
      title: string;
    };
    mediaInfo?: {
      thumbnails: Array<{ url: string }>;
    };
  };
  content: Array<string>;
  translationId: string;
  badgeText: string;
  vodAvailable: string;
}

export interface TFile {
  key: string;
  files: { [key: string]: { url: string } };
}

export interface TOffer {
  content: Array<string>;
}

export interface TPackage {
  id: string;
  translationId: string;
  imageId: string;
  badgeText: string;
}

export interface TOffers {
  channelPackages: Array<TChannel>;
  additionalPackages: Array<TPackage>;
  vodPackages: Array<TPackage>;
  defaultActive: string;
}

export const getTranslations = () => sequoiaInstance.get(URL.translation);

export const getFiles = () => sequoiaInstance.get(URL.files);

export const getChannels = () => beInstance.get(URL.channels);

export const getOffers = () => sequoiaInstance.get(URL.offers);

export const getOfferById = (id: string) =>
  beInstance.get(`${URL.offer}/${id}`);

export const getContentBlocks = () => sequoiaInstance.get(URL.contentBlocks);

export const showcaseChannels = (id: string) =>
  beInstance.get(URL.showcaseChannels(id));
