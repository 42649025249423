import { render, staticRenderFns } from "./SupportedDevices.vue?vue&type=template&id=21dac6ef&scoped=true"
import script from "./SupportedDevices.vue?vue&type=script&lang=ts"
export * from "./SupportedDevices.vue?vue&type=script&lang=ts"
import style0 from "./SupportedDevices.vue?vue&type=style&index=0&id=21dac6ef&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21dac6ef",
  null
  
)

export default component.exports