<template>
  <div class="price-plan-details">
    <h2 class="h1 modal-title">{{ name }}</h2>
    <div class="channels-count">{{ channelsCountText }}</div>
    <div v-for="channelsGroup in groups" :key="channelsGroup.name">
      <div v-if="getChannels(channelsGroup.name).length" class="group">
        <div class="group-info">
          <div class="group-image">
            <component :is="channelsGroup.image"></component>
          </div>
          <div class="group-name">
            {{ channelsGroup.name }}
          </div>
        </div>
        <div class="channels">
          <div
            v-for="channel in getChannels(channelsGroup.name)"
            :key="channel.id"
            class="channel"
          >
            <div
              :style="{ backgroundImage: `url(${getPosterUrl(channel, 100)})` }"
              class="channel-image"
            />
            <div class="channel-name">
              {{ channel.info.metaInfo.title.slice(4) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="disclaimer">
      Список каналов носит ознакомительный характер. Состав подписки,
      представленный на данной странице, может быть неполным. Сервис оставляет
      за собой право вносить изменения в подписку в любой момент.
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import HdIcon from '@/assets/svg/channel-groups/hd.svg';
import KidsIcon from '@/assets/svg/channel-groups/kids.svg';
import MoviesIcon from '@/assets/svg/channel-groups/movies.svg';
import MusicIcon from '@/assets/svg/channel-groups/music.svg';
import NewsIcon from '@/assets/svg/channel-groups/news.svg';
import ScienceIcon from '@/assets/svg/channel-groups/science.svg';
import EntertainmentIcon from '@/assets/svg/channel-groups/entertainment.svg';
import SportIcon from '@/assets/svg/channel-groups/sport.svg';
import AirIcon from '@/assets/svg/channel-groups/air.svg';
import LocalIcon from '@/assets/svg/channel-groups/local.svg';
import FourKIcon from '@/assets/svg/channel-groups/4k.svg';

@Component
export default class PricePlanDetails extends Vue {
  TYPE = TYPE;

  @Prop({ required: true })
  name!: string;

  @Prop({ required: true })
  channels!: Array<any>;

  @Prop({ required: true })
  channelsCount!: number;

  @Prop({ required: true })
  getPosterUrl!: Function;

  groups: { [key: string]: any } = {
    'HD-channels': {
      name: 'HD-каналы',
      image: HdIcon,
    },
    Kids: {
      name: 'Детские',
      image: KidsIcon,
    },
    Movies: {
      name: 'Кино',
      image: MoviesIcon,
    },
    Music: {
      name: 'Музыкальные',
      image: MusicIcon,
    },
    News: {
      name: 'Новостные',
      image: NewsIcon,
    },
    Knowledge: {
      name: 'Познавательные',
      image: ScienceIcon,
    },
    Entertainment: {
      name: 'Развлекательные',
      image: EntertainmentIcon,
    },
    Sports: {
      name: 'Спорт',
      image: SportIcon,
    },
    Terrestrial: {
      name: 'Эфирные',
      image: AirIcon,
    },
    Local: {
      name: 'Региональные',
      image: LocalIcon,
    },
    FourK: {
      name: '4К-каналы',
      image: FourKIcon,
    },
  };

  get channelsCountText() {
    if (this.TYPE === 'promo') {
      return `Каналов: до ${this.channelsCount}`;
    } else {
      return `Каналов: ${this.channelsCount}+`;
    }
  }

  getChannels(genre: string) {
    return this.channels.filter((channel) => {
      const channelInfo = channel ? channel.info : null;
      const genres = channelInfo ? channelInfo.metaInfo.genres : [];
      let rgxp = new RegExp(genre.toLowerCase(), 'g');
      return !!genres.find((item: string) => item.toLowerCase().match(rgxp));
    });
  }
}
</script>

<style lang="scss">
@import '../../../variables';

.price-plan-details {
  .channels-count {
    opacity: 0.8;
    font-size: 28px;
    font-weight: 500;
    margin-top: -100px;
    margin-bottom: 100px;

    @media #{$screen-xs-max} {
      font-size: 18px;
      margin-top: -50px;
      margin-bottom: 50px;
    }
  }

  .group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    overflow: hidden;

    @media #{$screen-md-only} {
      flex-direction: column;
    }
    @media (orientation: portrait) {
      flex-direction: column;
    }
  }

  .group-info {
    width: 45%;
    @media #{$screen-md-only} {
      width: 100%;
      margin-bottom: 60px;
    }
    @media (orientation: portrait) {
      width: 100%;
      margin-bottom: 60px;
    }
  }

  .group-image {
    margin-bottom: 20px;

    svg {
      width: 64px !important;
      height: 64px !important;

      path {
        fill: $yellow-color;
      }
      @media #{$screen-xs-max} {
        width: 48px !important;
        height: 48px !important;
      }
    }
  }

  .group-name {
    color: $yellow-color;
    font-size: 38px;
    line-height: 1;
    font-weight: 900;
    letter-spacing: 3.09px;
    text-transform: uppercase;
    margin-bottom: 20px;

    @media #{$screen-xs-max} {
      font-size: 22px;
      margin-bottom: 10px;
    }
  }

  .group-channels-count {
    opacity: 0.8;
    font-size: 28px;
    font-weight: 500;

    @media #{$screen-xs-max} {
      font-size: 18px;
    }
  }

  .channels {
    display: flex;
    flex-wrap: wrap;
    width: 55%;
    @media #{$screen-md-only} {
      margin-right: -115px;
      width: auto;
    }
    @media (orientation: portrait) {
      margin-right: -115px;
      width: auto;
    }
    @media #{$screen-xs-max} {
      flex-direction: column;
    }
  }

  .channel {
    width: 33.3%;
    padding-right: 5vw;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;

    @media #{$screen-md-only} {
      width: 213px + 115px;
      padding-right: 115px;
    }

    @media #{$screen-sm-only} {
      width: 213px + 115px;
      padding-right: 115px;
    }

    @media #{$screen-xs-max} {
      width: 50%;
      padding-right: 0;
      padding-bottom: 30px;
    }
  }

  .channel-image {
    width: 100%;
    height: 7vw;
    background: no-repeat center;
    background-size: cover;

    @media #{$screen-md-only} {
      height: 120px;
    }

    @media #{$screen-sm-only} {
      height: 120px;
    }

    @media #{$screen-xs-max} {
      height: 25vw;
    }
  }

  .channel-name {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.75px;
    line-height: 30px;
  }

  .disclaimer {
    margin-bottom: 60px;
  }
}
</style>
