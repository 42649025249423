export const dataLayerPush = (eventAction: string, eventLabel?: string) => {
  /*console.log('dataLayer', {
    event,
    eventCategory: `landing_${TYPE}`,
    eventAction,
    eventLabel
  });*/
  dataLayer.push({
    event: 'event-to-ga',
    eventCategory: `landing_${TYPE}`,
    eventAction,
    eventLabel,
  });
};
