var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',{staticClass:"header",class:{
    minimal: _vm.minimal,
    'fade-in': _vm.fadeIn,
    'fade-out': _vm.fadeOut,
    'slide-up': _vm.slideUp,
    'slide-down': _vm.slideDown,
  }},[_c('a',{staticClass:"logo",attrs:{"href":_vm.homeUrL}},[_c('img',{attrs:{"src":require("../assets/images/logo.svg"),"alt":""}})]),_c('div',{staticClass:"buttons"},[(_vm.screen > 1)?_c('Button',{staticClass:"visible-xs-max",attrs:{"noBg":true,"link":_vm.regUrl,"size":"l"},nativeOn:{"click":function($event){return _vm.dataLayerPush('reg_button', 'header')}}},[_vm._v(" Регистрация ")]):_vm._e(),_c('Button',{class:{ 'visible-xs-min': _vm.screen > 1 },attrs:{"noBg":true,"link":_vm.loginUrl,"size":"l"},nativeOn:{"click":function($event){return _vm.dataLayerPush('enter_button', 'header')}}},[_vm._v(" Войти ")]),_c('Button',{staticClass:"free-30days visible-xs-min",attrs:{"size":"l","link":_vm.regUrl},nativeOn:{"click":function($event){return _vm.dataLayerPush('reg_button', 'header')}}},[_vm._v(" 30 дней бесплатно"),_c('sup',[_vm._v("*")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }