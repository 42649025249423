export const BE_BASE_URL = 'https://fe.smotreshka.tv';
export const SEQUOIA_BASE_URL = process.env.VUE_APP_BASE_URL;

export const LANDING_TYPE = TYPE === 'promo' ? 'IPTVLanding' : 'OTTLanding';

export const CHANNEL_LIST_POSTER_HEIGHT = 34;

export const URL = {
  channels: 'channels',
  offers: 'siteConfig',
  translation: 'translation',
  files: 'fileRecord',
  offer: 'v2/offers',
  showcaseChannels: (offerId: string) =>
    `/offers/v3/${offerId}/showcase-channels`,
  regMainPage: `/reg?${
    TYPE === 'watch' ? 'from=watch&provider=59db5f83bd10231640a3d9d6' : ''
  }&utm_source=landing_${TYPE}&utm_campaign=reg_button_first_screen`,
  regHeader: `/reg?${
    TYPE === 'watch' ? 'from=watch&provider=59db5f83bd10231640a3d9d6' : ''
  }&utm_source=landing_${TYPE}&utm_campaign=reg_button_header`,
  login: `/?from_landing=true&utm_source=landing_${TYPE}&utm_campaign=enter_button`,
  home: `/?utm_source=landing_${TYPE}&utm_campaign=logo_button`,
  contentBlocks: 'cleanContentBlock',
};
