<template>
  <footer class="footer">
    <div class="footer__wrap">
      <div class="footer__disclaimer">
        <!-- Для IPTV версии(/promo) -->
        <div v-if="TYPE === 'promo'" v-html="disclaimerPromo" />
        <!-- Для OTT версии(/watch) -->
        <div v-else v-html="disclaimerWatch" />
      </div>
    </div>
    <div class="footer__wrap">
      <div class="footer-contacts">
        <div class="footer-contacts__item">
          <span class="footer__title">Партнерам:</span>
          <a
            href="mailto:isp@smotreshka.tv"
            target="_blank"
            class="footer__link"
          >
            isp@smotreshka.tv
          </a>
        </div>
        <div class="footer-contacts__item">
          <span class="footer__title">Абонентам:</span>
          <a
            href="mailto:help@smotreshka.tv"
            target="_blank"
            class="footer__link"
          >
            help@smotreshka.tv
          </a>
        </div>
        <div class="footer-contacts__item">
          <span class="footer__title">Агентам продаж:</span>
          <a
            href="mailto:partner@smotreshka.tv"
            target="_blank"
            class="footer__link"
          >
            partner@smotreshka.tv
          </a>
        </div>
        <div class="footer-contacts__item">
          <a
            href="https://smotreshka.tv/smotreshka-box"
            target="_blank"
            class="footer__link"
          >
            Купить приставки
          </a>
        </div>
      </div>
      <div class="footer-socials">
        <span class="footer__title">Нам важно твоё мнение!</span>
        <div class="footer-socials__wrap">
          <div class="footer-socials__icon footer-socials__icon--e">
            <a
              href="https://ask.smotreshka.tv//?utm_source=website"
              target="_blank"
            >
              <SmotreshkaIcon />
            </a>
          </div>
          <div class="footer-socials__icon footer-socials__icon--tg">
            <a href="tg://resolve?domain=smotreshka " target="_blank">
              <TelegramIcon />
            </a>
          </div>
          <div class="footer-socials__icon footer-socials__icon--vk">
            <a href="https://vk.com/smotreshka" target="_blank">
              <VkIcon />
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import SmotreshkaIcon from '@/assets/svg/social-networks/e.svg';
import TelegramIcon from '@/assets/svg/social-networks/telegram.svg';
import VkIcon from '@/assets/svg/social-networks/vk.svg';
import FbIcon from '@/assets/svg/social-networks/fb.svg';
import { Action, State } from 'vuex-class';

@Component({
  components: { SmotreshkaIcon, TelegramIcon, VkIcon, FbIcon },
})
export default class Footer extends Vue {
  TYPE = TYPE;

  @Action('loadContentBlocks')
  loadContentBlocks!: Function;

  @State('contentBlocks')
  contentBlocks!: { [key: string]: any };

  async mounted() {
    await this.loadContentBlocks();
  }

  get disclaimerPromo() {
    return this.contentBlocks.disclaimer_promo
      ? this.contentBlocks.disclaimer_promo.ru
      : '';
  }

  get disclaimerWatch() {
    return this.contentBlocks.disclaimer_watch
      ? this.contentBlocks.disclaimer_watch.ru
      : '';
  }
}
</script>

<style scoped lang="scss">
@import '../variables';

.footer {
  color: #ffffff;
  width: 100%;
  bottom: 0;
  left: 0;

  &__wrap {
    padding: 32px 10vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1584px;
    margin: 0 auto;
    @media (max-width: 1400px) {
      padding: 32px 40px;
    }
    @media #{$screen-xs-max} {
      flex-direction: column;
      padding: 20px;
    }
  }
  &__title {
    display: inline-block;
    font-size: 14px;
    line-height: 1;
    font-weight: 300;
    letter-spacing: -0.4px;
    color: #ffffff;
    margin-right: 5px;
  }
  &__link {
    display: inline-block;
    text-decoration: none;
    font-size: 14px;
    line-height: 1;
    font-weight: 300;
    letter-spacing: -0.4px;
    color: #ffdb4d;

    &:hover {
      text-decoration: underline;
    }
  }
  &__disclaimer {
    font-size: 10px;
    line-height: 15px;
    color: #777777;

    &::v-deep {
      mark {
        background: none;
        color: inherit;
      }
    }
  }
}

.footer-socials {
  display: flex;
  align-items: center;
  @media #{$screen-xs-max} {
    flex-direction: column;
    margin-top: 15px;
  }
  &__wrap {
    display: flex;
    align-items: center;
  }
  &__icon {
    margin-left: 30px;
    @media #{$screen-xs-max} {
      margin-top: 10px;
    }
    &:first-of-type {
      @media #{$screen-xs-max} {
        margin-left: 0;
      }
    }
  }
}
.footer-contacts {
  text-align: left;
  &__item + &__item {
    margin-top: 5px;
  }
}
</style>
