<template>
  <div
    class="screen"
    ref="screen"
    :class="{
      'slide-left': slideLeft,
      'slide-right': slideRight,
      'slide-up': slideUp,
      'slide-down': slideDown,
      scrollable,
    }"
    v-on:scroll="$emit('scroll', $refs.screen.scrollTop)"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class Screen extends Vue {
  @Prop({ required: false })
  slideLeft?: boolean;

  @Prop({ required: false })
  slideRight?: boolean;

  @Prop({ required: false })
  slideUp?: boolean;

  @Prop({ required: false })
  slideDown?: boolean;

  @Prop({ required: false })
  scrollable?: boolean;
}
</script>

<style scoped lang="scss">
.screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: transform 1s ease;
  animation-delay: 0.5s;

  &.slide-left {
    transform: translateX(-100%);
  }

  &.slide-right {
    transform: translateX(100%);
  }

  &.slide-up {
    transform: translateY(-100%);
  }

  &.slide-down {
    transform: translateY(100%);
  }

  &.scrollable {
    overflow: auto;
  }
}
</style>
