<template>
  <div class="price-plan" :class="[{ active }, TYPE]">
    <button
      class="price-plan-inner"
      :class="{ active }"
      v-on:click="$emit('click')"
    >
      <div v-if="badge && badge.length" class="badge">
        {{ badge }}
      </div>
      <div class="price-plan-title">{{ name }}</div>
      <div v-if="price" class="price-plan-price">
        <Price :value="price" />
      </div>
      <div class="price-plan-info">{{ info }}</div>
      <div class="price-plan-vod">
        {{ vod }}
      </div>
    </button>
    <button class="price-plan-more" :class="{ active }" @click="onClickMore()">
      Подробнее о пакете
    </button>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Price from '@/components/Ui/Price.vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { dataLayerPush } from '@/services/GoogleAnalyticsService';

@Component({ components: { Price } })
export default class PricePlan extends Vue {
  @Prop({ required: true })
  active!: boolean;

  @Prop({ required: true })
  id!: string;

  @Prop({ required: true })
  name!: string;

  @Prop({ required: true })
  price!: string;

  @Prop({ required: true })
  info!: string;

  @Prop()
  badge?: string;

  @Prop()
  vod?: string;

  TYPE = TYPE;

  onClickMore() {
    this.$emit('more');
    dataLayerPush('package_list_click', this.id);
  }
}
</script>

<style scoped lang="scss">
@import '../../../variables';

.price-plan {
  position: relative;
  text-align: center;
  color: #000;
  width: 220px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 4px;
  transition: transform 0.3s ease;

  @media #{$screen-xs-max} {
    align-items: center;
    flex: 1 0 auto;
    margin: 0 4vw;
    width: 60vw;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 8px;
    transition: transform 0.3s ease;
  }

  &:hover {
    transform: scale(1.1);
  }

  &.active {
    position: relative;
    z-index: 1;
    @media #{$screen-xs-max} {
      height: 214px;
      margin: 0 4vw;
      width: 75vw;
      transform: none;
    }

    &:before {
      background-color: #ffdb4d;
      box-shadow: 0 2px 20px rgba(255, 219, 77, 0.4);
      transform: scale(1.16);
      @media #{$screen-sm-only} {
        transform: scale(1.15);
      }
      @media #{$screen-xs-max} {
        transform: none;
      }
    }

    .badge {
      top: -26px;

      @media #{$screen-xs-max} {
        top: -16px;
      }
    }
  }

  &:first-child {
    @media #{$screen-xs-max} {
      margin-left: 12.5vw;
    }
  }

  &.promo {
    @media #{$screen-sm-only} {
      width: 40vw;
      margin-bottom: 8px;
    }
  }

  .badge {
    position: absolute;
    left: 50%;
    top: -14px;
    max-width: 220px;
    overflow: hidden;
    padding: 6px 10px;
    white-space: nowrap;
    font-weight: bold;
    color: #fff;
    border-radius: 2px;
    transform: translateX(-50%);
    background-color: #7800c2;
    text-transform: uppercase;
    transition: top 0.3s ease;
    text-overflow: ellipsis;

    @media #{$screen-xs-max} {
      top: -10px;
    }
  }
}

.price-plan-inner {
  border: 0;
  background: none;
  cursor: pointer;
  position: relative;
  flex: 1;
}

.price-plan-title {
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.23px;
  line-height: 24px;
}

.price-plan.active .price-plan-title {
  @media #{$screen-xs-max} {
    margin-bottom: 20px;
  }
}

.price-plan-count,
.price-plan-more {
  font-size: 13px;
  letter-spacing: 0.29px;
  line-height: 18px;
}

.price-plan-count {
  font-weight: 300;
  display: block;
  margin-bottom: 8px;
}

.price-plan-more {
  background: none;
  border: 0;
  font-weight: bold;
  opacity: 0;
  position: relative;
  transition: all 0.3s ease;
  max-height: 0;
  overflow: hidden;

  &:hover {
    text-decoration: underline;
  }

  &.active {
    max-height: 200px;
    opacity: 1;
    @media #{$screen-xs-max} {
      background: rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      display: block;
      margin-bottom: 15px;
      padding: 15px 0;
      width: 200px;
    }
  }
}

.price-plan-info,
.price-plan-vod {
  font-size: 13px;
}
</style>
