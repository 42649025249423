<template>
  <div class="stores" :class="{ stretched }">
    <div v-for="store in stores" :key="store.name" class="store">
      <a v-if="store.link" :href="store.link" target="_blank">
        <img :src="store.image" alt="" />
      </a>
      <img v-else :src="store.image" alt="" />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class Stores extends Vue {
  @Prop({ required: false })
  stretched?: boolean;

  stores = [
    {
      name: 'AppStore',
      image: require('../assets/images/stores/app-store.svg'),
    },
    {
      name: 'Google Play',
      image: require('../assets/images/stores/google-play.svg'),
    },
    {
      name: 'LG SmartTV',
      image: require('../assets/images/stores/lg-smart-tv.svg'),
    },
    {
      name: 'Samsung SmartTV',
      image: require('../assets/images/stores/samsung-smart-tv.svg'),
    },
    {
      name: 'AndroidTV',
      image: require('../assets/images/stores/android-tv.svg'),
    },
  ];
}
</script>

<style scoped lang="scss">
@import '../variables';

.stores {
  display: flex;
  align-items: center;
  &.stretched {
    justify-content: space-between;
    @media #{$screen-xs-max} {
      justify-content: center;
    }
  }

  @media #{$screen-xs-max} {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.store {
  margin-right: 30px;
  @media #{$screen-xs-max} {
    width: 23vw;
    margin: 0 20px;
  }
  img {
    width: 100%;
  }
  &:last-of-type {
    margin-right: 0;
  }
}
.stretched .store {
  margin: 0;
  @media #{$screen-xs-max} {
    margin: 0 10px;
  }
}
</style>
