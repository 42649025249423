import 'core-js/stable'; // polyfills for IE11
import 'regenerator-runtime/runtime';

import Vue from 'vue';
import App from './App.vue';
import store from './store';

Vue.config.productionTip = false;

new Vue({
  store,
  render: (h) => h(App),
}).$mount('#app');
