<template>
  <div class="laptop">
    <div class="laptop-text" :class="{ visible }">
      <h1 class="h1">Смотри на&nbsp;разных устройствах</h1>
      <h3 class="h3">
        Поставь передачу на&nbsp;паузу и&nbsp;продолжай просмотр в&nbsp;любой
        момент и&nbsp;где угодно
      </h3>
    </div>
    <div class="laptop-playback" :class="{ visible }">
      <div class="laptop-video-container">
        <video
          muted="muted"
          loop="loop"
          playsinline
          autoplay
          class="laptop-video"
        >
          <source type="video/mp4" src="../../../assets/video/video-2.mp4" />
          <source
            type='video/webm; codecs="vp8, vorbis"'
            src="../../../assets/video/video-2.webm"
          />
        </video>
      </div>
      <img
        class="laptop-frame"
        src="../../../assets/images/rewind/laptop-frame.png"
        alt=""
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class ScreenRewindOnLaptop extends Vue {
  @Prop({ required: false })
  visible?: boolean;
}
</script>

<style scoped lang="scss">
@import '../../../variables';

.laptop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // transform: translateX(0);
  // transition: transform 1s ease;
}

.laptop-text {
  padding-top: $header-height;
  padding-left: $side-padding;
  transform: translateX(-300%);
  transition: transform 1s ease;

  @media (orientation: portrait) {
    padding-top: $header-height-portrait;
  }

  @media #{$screen-xs-max} {
    padding-left: $side-padding-xs;
    padding-top: $header-height-xs;
  }

  &.visible {
    transform: translateX(0);
  }

  h2 {
    max-width: 60%;
    @media (orientation: portrait) {
      max-width: none;
    }
  }

  h3 {
    max-width: 30%;
    @media (orientation: portrait) {
      max-width: none;
    }
  }
}

.laptop-playback {
  position: absolute;
  bottom: 0;
  width: 110vh;
  height: 62vh;
  left: 65%;
  transform: translateX(-300%);
  transition: transform 1s ease;

  @media (orientation: portrait) {
    width: 80vw;
    height: 45vw;
    left: 50%;
    bottom: 3%;
  }

  @media #{$screen-xs-max} and (orientation: portrait) {
    bottom: 7vh;
  }

  &.visible {
    transform: translateX(-50%);
  }
}

.laptop-video-container {
  position: absolute;
  left: 10%;
  right: 10%;
  top: 10%;
  bottom: 10%;
}

.laptop-frame {
  width: 100%;
  height: 100%;
  position: relative;
}

.laptop-video {
  width: 100%;
  height: 93%;
}
</style>
