<template>
  <div class="thumbnails">
    <!--
    <div
      class="thumbnails-sequence"
      v-for="(sequence, sequenceIndex) in sequences"
      :key="sequenceIndex"
      :class="{ [`sequence${sequenceIndex}`]: true, visible }"
    >
      <div
        v-for="(layer, layerIndex) in sequence.layers"
        :key="sequenceIndex + layerIndex"
        :class="`animate-layer${layerIndex}`"
        class="thumbnail"
      >
        <div
          v-for="(image, imageIndex) in layer.images"
          :key="sequenceIndex + layerIndex + imageIndex"
        >
          <Thumbnail
            :class="`layer${layerIndex}-${imageIndex}`"
            :image="image"
          />
        </div>
      </div>
    </div>
    -->
    <div class="thumbnails-audience" :class="{ visible }">
      <img src="../../../assets/images/sports_bg.png" alt="" />
    </div>
    <div class="thumbnails-radial-gradient" :class="{ visible }"></div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
// import Thumbnail from '@/components/Screens/Thumbnails/Thumbnail.vue';

// @Component({ components: { Thumbnail } })
@Component
export default class ScreenThumbnails extends Vue {
  @Prop({ required: false })
  blur?: boolean;

  @Prop({ required: false })
  visible?: boolean;

  sequences = [
    {
      layers: [
        {
          images: [
            require('../../../assets/images/channels/game_of_thrones.jpg'),
          ],
        },
        {
          images: [
            require('../../../assets/images/channels/nickelodeon.jpg'),
            require('../../../assets/images/channels/match.jpg'),
            require('../../../assets/images/channels/discovery.jpg'),
            require('../../../assets/images/channels/tnt.jpg'),
          ],
        },
        {
          images: [
            require('../../../assets/images/channels/handmaids_tail.jpg'),
            require('../../../assets/images/channels/judge_judy.jpg'),
            require('../../../assets/images/channels/camping.jpg'),
            require('../../../assets/images/channels/911.jpg'),
          ],
        },
        {
          images: [
            require('../../../assets/images/channels/first.jpg'),
            require('../../../assets/images/channels/mult.jpg'),
          ],
        },
      ],
    },
    {
      layers: [
        {
          images: [require('../../../assets/images/channels/ben_10.jpg')],
        },
        {
          images: [
            require('../../../assets/images/channels/tlc.jpg'),
            require('../../../assets/images/channels/spike.jpg'),
            require('../../../assets/images/channels/rtg.jpg'),
            require('../../../assets/images/channels/paramount_comedy_hd.jpg'),
          ],
        },
        {
          images: [
            require('../../../assets/images/channels/adventure_time.jpg'),
            require('../../../assets/images/channels/ivanovy.jpg'),
            require('../../../assets/images/channels/krasnie_braslety.jpg'),
            require('../../../assets/images/channels/loony_tunes.jpg'),
          ],
        },
        {
          images: [
            require('../../../assets/images/channels/eurosporthd.jpg'),
            require('../../../assets/images/channels/amediapremiumhd.jpg'),
          ],
        },
      ],
    },
  ];
}
</script>

<style scoped lang="scss">
@import '../../../variables';
/*
.thumbnails {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $thumbnails-screen-z-index;
}

.thumbnails-sequence {
  position: absolute;
  width: 100vw;
  min-width: 1920px;
  height: 100vh;
  left: 0;
  overflow: hidden;
  background-size: cover;
  opacity: 0;
  transform: translateX(-200%);
  transition: transform 1s ease, opacity 1s ease;

  &.visible {
    transform: translateX(0);
    opacity: 1;
  }
}

.thumbnail {
  width: 100vw;
  min-width: 1920px;
  height: 100vh;
  position: absolute;
  left: 0;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-delay: 0.1s;
  &.animate-layer0 {
    animation-duration: 85s;
    z-index: $thumbnails-screen-z-index + 4;
  }
  &.animate-layer1 {
    animation-duration: 118s;
    z-index: $thumbnails-screen-z-index + 3;
  }
  &.animate-layer2 {
    animation-duration: 100s;
    z-index: $thumbnails-screen-z-index + 2;
  }
  &.animate-layer3 {
    animation-duration: 125s;
    z-index: $thumbnails-screen-z-index + 1;
  }
  &.blur {
    filter: blur(2px);
  }
}

.sequence0 .thumbnail {
  // animation-name: Sequence1;
}

.sequence1 .thumbnail {
  // animation-name: Sequence2;
}
*/

.thumbnails-audience {
  position: absolute;
  bottom: 0;
  width: 100vw;
  transform: translateY(100%) scale(2);
  transition: transform 1s ease;
  z-index: $thumbnails-screen-z-index + 8;
  img {
    display: block;
    width: 100%;
  }

  &.visible {
    transform: translateY(40%);
  }
}

.thumbnails-radial-gradient {
  background-image: radial-gradient(
    circle at 50% 52%,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.2) 60%,
    rgba(0, 0, 0, 0.5) 70%,
    rgba(0, 0, 0, 1) 89%
  );
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  transition: opacity 1s ease;
  z-index: $thumbnails-screen-z-index + 9;

  &.visible {
    opacity: 1;
  }
}

@keyframes Sequence1 {
  0% {
    transform: translateX(0);
  }
  45% {
    transform: translateX(-100%);
    visibility: visible;
  }
  45.1% {
    transform: translateX(-100%);
    visibility: hidden;
  }
  45.2% {
    transform: translateX(100%);
    visibility: hidden;
  }
  45.3% {
    transform: translateX(100%);
    visibility: visible;
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes Sequence2 {
  0% {
    transform: translateX(100%);
    visibility: visible;
  }
  45% {
    transform: translateX(0);
    visibility: visible;
  }
  99% {
    transform: translateX(-100%);
    visibility: hidden;
  }
  100% {
    transform: translateX(100%);
    visibility: hidden;
  }
}
</style>
