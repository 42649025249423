<template>
  <div class="navigation">
    <button
      class="item"
      :class="{ active: item.activeFor.includes(screen) }"
      type="button"
      v-on:click="$emit('changeScreen', item.screenIndex)"
      v-for="(item, index) in items"
      :key="item.name"
    >
      <span class="text">
        <span class="text-bg"></span>
        <span class="text-inner">{{ item.name }}</span>
      </span>
      <span class="number">0{{ index + 1 }}</span>
    </button>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class Navigation extends Vue {
  @Prop({ required: false })
  screen?: number;

  items = [
    {
      activeFor: [0, 1, 2],
      name: 'Смотрёшка',
      screenIndex: 0,
    },
    {
      activeFor: [3, 4],
      name: 'Пауза и перемотка',
      screenIndex: 3,
    },
    {
      activeFor: [5],
      name: 'Мультискрин',
      screenIndex: 5,
    },
    {
      activeFor: [6],
      name: 'Архив передач',
      screenIndex: 6,
    },
    {
      activeFor: [7],
      name: 'До пяти устройств',
      screenIndex: 7,
    },
    {
      activeFor: [8],
      name: 'Пакеты каналов',
      screenIndex: 8,
    },
  ];
}
</script>

<style scoped lang="scss">
@import '../variables';

.navigation {
  color: #fff;
  display: flex;
  font-size: 16px;
  flex-direction: column;
  transform: translateY(-50%);
  position: fixed;
  right: 32px;
  top: 50%;
  z-index: 100;

  @media #{$screen-xs-max} {
    display: none;
  }

  .item {
    background: none;
    border: 0;
    margin-bottom: 24px;
    opacity: 0.7;
    position: relative;
    width: 16px;
    height: 16px;
    line-height: 1;

    &:before {
      content: '';
      width: 16px;
      height: 16px;
      border: 2px solid;
      display: block;
      border-radius: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 1;
      transition: all 0.3s ease;
    }

    .number {
      font-size: 18px;
      position: absolute;
      transform: translateX(50%);
      font-weight: 900;
      width: 16px;
      height: 16px;
      opacity: 0;
      top: 0;
      left: 0;
      text-align: center;
      transition: all 0.3s ease;
    }

    .text {
      letter-spacing: -0.1px;
      opacity: 0;
      padding: 8px 56px 8px 16px;
      position: absolute;
      right: -16px;
      transform: translateY(-52%);
      top: 50%;
      white-space: nowrap;

      .text-bg {
        content: '';
        background: #000;
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 0;
        transition: all 0.3s ease;
      }

      .text-inner {
        display: block;
        position: relative;
        transition: all 0.3s ease;
      }
    }

    &:hover,
    &.active {
      opacity: 1;

      &:before {
        opacity: 0;
        transform: translateX(-50%);
      }

      .number {
        opacity: 1;
        transform: translateX(0);
      }

      .text {
        animation-name: text-appear;
        animation-duration: 2s;
      }

      .text-inner {
        animation-name: text-inner-appear;
        animation-duration: 2s;
      }

      .text-bg {
        animation-name: text-bg-appear;
        animation-duration: 1.9s;
      }
    }
  }
}

@keyframes text-appear {
  0% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes text-inner-appear {
  80% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-20%);
  }
}

@keyframes text-bg-appear {
  0% {
    max-width: 0;
  }

  40% {
    max-width: 300px;
  }

  80% {
    max-width: 300px;
  }

  90% {
    max-width: 0;
  }
}
</style>
