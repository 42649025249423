<template>
  <div class="price-plan-channel" v-bind:class="{ active }">
    <img v-bind:src="image" alt="" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class PricePlanChannel extends Vue {
  @Prop({ required: false })
  active?: boolean;

  @Prop({ required: false })
  image!: string;
}
</script>

<style scoped lang="scss">
.price-plan-channel {
  position: relative;
  margin: 0 4px 8px;
  opacity: 0.15;
  transition: opacity 0.3s ease;

  &.active {
    opacity: 1;
  }

  img {
    width: 60px;
    height: 34px;
  }
}
</style>
