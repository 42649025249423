<template>
  <div class="plans">
    <h2 class="h2" v-if="TYPE === 'promo'">
      ТАРИФЫ ДЛЯ АБОНЕНТОВ ОПЕРАТОРОВ СВЯЗИ
    </h2>
    <h2 class="h2" v-else>ПАКЕТЫ КАНАЛОВ</h2>
    <div class="plans-list">
      <div class="plans-list-inner desktop" :class="TYPE">
        <PricePlan
          v-for="(offer, index) in offers.channelPackages"
          :key="offer.id"
          :id="offer.id"
          :active="index === currentPricePlanIndex"
          :name="getRuTranslation(offer.translationId)"
          :badge="offer.badgeText ? getRuTranslation(offer.badgeText) : ''"
          :price="offer.price"
          :info="offer.content ? setChannelsText(offer.content.length) : ''"
          :vod="offer.vodAvailable ? getRuTranslation(offer.vodAvailable) : ''"
          v-on:click="currentPricePlanIndex = index"
          v-on:more="showModal = true"
        />
      </div>
      <div
        class="plans-list-inner mobile"
        :style="{ transform: `translateX(-${currentPricePlanIndex * 68}vw)` }"
        ref="scroll"
      >
        <PricePlan
          v-for="(offer, index) in offers.channelPackages"
          :key="offer.id"
          :id="offer.id"
          :active="index === currentPricePlanIndex"
          :name="getRuTranslation(offer.translationId)"
          :badge="offer.badgeText ? getRuTranslation(offer.badgeText) : ''"
          :price="offer.price"
          :info="offer.content ? setChannelsText(offer.content.length) : ''"
          :vod="offer.vodAvailable ? getRuTranslation(offer.vodAvailable) : ''"
          v-on:click="currentPricePlanIndex = index"
          v-on:more="showModal = true"
        />
      </div>
    </div>
    <div class="plans-channels">
      <PricePlanChannel
        v-for="channel in manuallyFilteredChannels"
        :key="channel.id"
        :active="isActiveChannel(channel)"
        :image="getPosterUrl(channel)"
      />
    </div>
    <Modal :visible="showModal" v-on:close="showModal = false">
      <PricePlanDetails
        :name="currentPricePlanName"
        :channels="pricePlanChannels"
        :channelsCount="pricePlanChannelsCount"
        :getPosterUrl="getPosterUrl"
      />
    </Modal>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Hammer from 'hammerjs';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';
import PricePlan from './PricePlan.vue';
import PricePlanDetails from './PricePlanDetails.vue';
import PricePlanChannel from './PricePlanChannel.vue';
import { CHANNEL_LIST_POSTER_HEIGHT } from '@/constants';
import { State } from 'vuex-class';
import { TChannel, TOffers } from '@/api';

@Component({
  components: {
    Modal,
    PricePlan,
    PricePlanDetails,
    PricePlanChannel,
  },
})
export default class ScreenPricePlans extends Vue {
  currentPricePlanIndex: number = 0;
  showModal: boolean = false;

  @Prop({ required: true })
  getRuTranslation!: Function;

  @State('offers')
  offers!: TOffers;

  @State('channels')
  channels!: Array<TChannel>;

  TYPE = TYPE;

  mounted() {
    const hammer = new Hammer(this.$refs.scroll as HTMLElement);
    hammer.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL });
    hammer.on('swipeleft swiperight', this.onScroll);
    this.currentPricePlanIndex = this.offers.channelPackages.findIndex(
      (item) => item.id === this.offers.defaultActive
    );
  }

  onScroll(e: HammerInput) {
    if (e.direction === Hammer.DIRECTION_LEFT) {
      this.currentPricePlanIndex += 1;
    } else if (e.direction === Hammer.DIRECTION_RIGHT) {
      this.currentPricePlanIndex -= 1;
    }
    if (this.currentPricePlanIndex < 0) {
      this.currentPricePlanIndex = this.offers.channelPackages.length - 1;
    }
    if (this.currentPricePlanIndex > this.offers.channelPackages.length - 1) {
      this.currentPricePlanIndex = 0;
    }
  }

  get currentPricePlan() {
    return this.offers.channelPackages[this.currentPricePlanIndex];
  }

  get currentPricePlanName() {
    return this.currentPricePlan
      ? this.getRuTranslation(this.currentPricePlan.translationId)
      : '';
  }

  get manuallyFilteredChannels() {
    const l = this.offers.channelPackages.length;
    let temp: Array<string> = [];
    for (let i = 0; i < l; i++) {
      temp = Array.from(
        new Set(temp.concat(this.offers.channelPackages[i].content))
      );
    }
    return this.channels.filter((channel) => temp.includes(channel.id));
  }

  get pricePlanChannels() {
    return this.channels.filter((channel) => {
      if (this.currentPricePlan) {
        return this.currentPricePlan.content.includes(channel.id);
      }
    });
  }

  get pricePlanChannelsCount() {
    return this.currentPricePlan ? this.currentPricePlan.content.length : 0;
  }

  getPosterUrl(channel: TChannel, height = CHANNEL_LIST_POSTER_HEIGHT) {
    const url =
      channel.info &&
      channel.info.mediaInfo &&
      channel.info.mediaInfo.thumbnails &&
      channel.info.mediaInfo.thumbnails.length &&
      channel.info.mediaInfo.thumbnails[0].url;

    if (!url) return '';

    const width = Math.round((height * 16) / 9);

    return `${url}?width=${width}&height=${height}`;
  }

  isActiveChannel(channel: TChannel) {
    const offers = this.offers.channelPackages;
    const offer = offers[this.currentPricePlanIndex];

    return offer ? offer.content.includes(channel.id) : false;
  }

  setChannelsText(length: number) {
    if (this.TYPE === 'promo') {
      return `Каналов: до ${length}`;
    } else {
      return `Каналов: ${length}+`;
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../variables';

.plans {
  overflow: auto;
  padding-top: $header-height;

  @media (orientation: portrait) {
    padding-top: $header-height-portrait;
  }

  @media #{$screen-xs-max} {
    padding-top: $header-height-xs;
  }

  h2 {
    text-align: center;
  }
}

.plans-list {
  margin: 0 0 48px;
  width: 100%;
  @media #{$screen-xs-max} {
    max-width: 676px;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
  }
}

.plans-list-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  @media #{$screen-xs-max} {
    min-height: 220px;
    justify-content: flex-start;
  }

  &.desktop {
    display: flex;
    @media #{$screen-xs-max} {
      display: none;
    }
  }

  &.mobile {
    display: none;
    @media #{$screen-xs-max} {
      display: flex;
    }
  }

  &.promo {
    @media #{$screen-sm-only} {
      flex-wrap: wrap;
    }
  }
}

.plans-channels {
  display: flex;
  flex-wrap: wrap;

  @media #{$screen-xs-max} {
    display: none;
  }
}
</style>
