<template>
  <div class="intro" :class="{ visible }">
    <div class="intro-inner">
      <Logo class="logo" />
      <div class="slogan">
        Более 300 телеканалов, архив до 14 дней,<br />
        пауза и перемотка,
        {{ TYPE === 'promo' ? '4 онлайн кинотеатра' : '5 онлайн кинотеатров' }}
        - в одном приложении!
      </div>
      <div class="button">
        <Button
          :glow="true"
          :link="regUrl"
          v-on:click.native="dataLayerPush('reg_button', 'first_screen')"
        >
          30 дней бесплатно<sup>*</sup>
        </Button>
      </div>
      <div class="note">Отказаться можно в любой момент</div>
      <NextScreenIcon />
      <div class="slogan">Телевидение, которое всегда с тобой</div>
      <Stores :stretched="true" />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Button from '@/components/Ui/Button.vue';
import Stores from '@/components/Stores.vue';
import NextScreenIcon from '@/components/Screens/Intro/NextScreenIcon.vue';
import Logo from '@/assets/svg/sm-logo-text.svg';
import { URL } from '@/constants';
import { dataLayerPush } from '@/services/GoogleAnalyticsService';

@Component({ components: { Button, Stores, NextScreenIcon, Logo } })
export default class ScreenIntro extends Vue {
  @Prop({ required: false })
  visible?: boolean;

  regUrl = URL.regMainPage;
  dataLayerPush = dataLayerPush;
  TYPE = TYPE;
}
</script>

<style scoped lang="scss">
@import '../../../variables';

.intro {
  position: relative;
  height: 100%;
  transition: opacity 1s ease;
  background-image: radial-gradient(
    circle at 50% 50%,
    rgba(0, 0, 0, 0.64),
    rgba(0, 0, 0, 0)
  );
  padding: 15px $side-padding;
  opacity: 0;
  z-index: $intro-screen-z-index;

  @media #{$screen-xs-max} {
    padding: 15px $side-padding-xs;
  }

  &.visible {
    opacity: 1;
    transition: opacity 0.5s ease;
  }
}

.intro-inner {
  max-width: 900px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
}

.logo {
  display: block;
  width: 100%;
  max-width: 512px;
  margin: 0 auto 30px;
  opacity: 0.75;
  @media #{$screen-xs-max} {
    max-width: 270px;
    margin-bottom: 10px;
  }
}

.slogan {
  color: #f7decb;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.32px;
  text-transform: uppercase;
  margin-bottom: 70px;

  @media #{$screen-xs-max} {
    font-size: 10px;
    line-height: 1.3;
    letter-spacing: 0.9px;
    text-align: center;
  }
}

.note {
  opacity: 0.7;
  color: #ffffff;
  font-size: 10px;
  font-weight: 300;
  letter-spacing: 0.6px;
  margin-bottom: 10vh;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
</style>
