<template>
  <div class="loaderContainer">
    <div class="loaderWrapper">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50"
        viewBox="0 0 50 50"
      >
        <circle
          cx="24"
          cy="24"
          r="24"
          fill="none"
          fill-rule="evenodd"
          stroke="#233046"
          stroke-width="2"
          transform="translate(1 1)"
        />
      </svg>
      <svg
        class="loaderRunner"
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50"
        viewBox="0 0 50 50"
      >
        <path
          id="spinner-accent-stroke"
          fill="none"
          fill-rule="evenodd"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M26 1c7.954.326 14.91 4.524 19.033 10.759"
        />
      </svg>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class Loader extends Vue {}
</script>
<style lang="scss">
@import '@/variables';

.loaderContainer {
  margin: 0;
  height: calc(100vh - 210px); // 100vh - footer-height
  width: 100%;
  position: relative;

  @media #{$screen-xs-max} {
    height: calc(100vh - 270px); // 100vh - footer-height for mobile
  }

  .loaderWrapper {
    position: relative;
    width: 100%;
    height: 100%;

    img,
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -25px;
      margin-top: -25px;

      &.loaderRunner {
        animation: rotation 1s infinite ease-in;
        stroke: #ffc125;
      }
    }
  }
}

@keyframes rotation {
  from {
    transform: rotate(-40deg);
  }
  to {
    transform: rotate(320deg);
  }
}
</style>
