<template>
  <div class="playback-recorder">
    <div class="playback-recorder-title">
      <h2 class="h1">Посмотри то, что пропустил</h2>
      <h3 class="h3" :class="{ 'slide-up': slideUp, 'slide-down': slideDown }">
        Ты&nbsp;не&nbsp;ограничен прямым эфиром, Смотри любые фильмы и передачи
        в&nbsp;записи
      </h3>
    </div>
    <div class="playback-recorder-ribbon">
      <div
        class="playback-recorder-phone"
        :class="{ 'slide-up': slideUp, 'slide-down': slideDown }"
      >
        <img
          src="../../../assets/images/playback-recorded/phone.png"
          alt=""
          class="playback-recorder-phone-image"
        />
      </div>
      <div class="playback-recorder-images">
        <div
          class="playback-recorder-image"
          v-for="(item, index) in items"
          :key="index"
        >
          <img :src="item.image" alt="" :class="{ blur: item.current }" />
        </div>
      </div>
      <div
        class="playback-recorder-names"
        :class="{ 'slide-up': slideUp, 'slide-down': slideDown }"
      >
        <div
          class="playback-recorder-name"
          :class="{ current: item.current }"
          v-for="(item, index) in items.filter((one) => one.name)"
          :key="index"
        >
          <div class="title">{{ item.name }}</div>
          <div class="time">{{ item.dateTime }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class ScreenPlaybackRecorded extends Vue {
  @Prop({ required: false })
  slideUp?: boolean;

  @Prop({ required: false })
  slideDown?: boolean;

  items = [
    {
      image: require('../../../assets/images/channels/sredne.jpg'),
    },
    {
      image: require('../../../assets/images/channels/nachalo.jpg'),
      name: 'Начни сначала',
      dateTime: 'Вчера, 16:00',
    },
    {
      image: require('../../../assets/images/channels/gnev.jpg'),
      name: 'Гнев человеческий',
      dateTime: 'Вчера, 17:30',
    },
    {
      image: require('../../../assets/images/channels/zhena.jpg'),
      name: 'Притворись моей женой',
      dateTime: 'Вчера, 19:00',
    },
    {
      image: require('../../../assets/images/channels/chelovek.jpg'),
      name: 'Человек паук. Возвращение домой',
      dateTime: 'Вчера, 21:00',
      current: true,
    },
    {
      image: require('../../../assets/images/channels/dzhumanzhy.jpg'),
      name: 'Джуманджи: Новый уровень',
      dateTime: 'Вчера, 22:30',
    },
    {
      image: require('../../../assets/images/channels/bogatyr.jpg'),
      name: 'Последний богатырь посланник тьмы служанки',
      dateTime: 'Вчера, 23:00',
    },
    {
      image: require('../../../assets/images/channels/mib.jpg'),
      name: 'Люди в черном',
      dateTime: 'Вчера, 23:30',
    },
    {
      image: require('../../../assets/images/channels/monstry.jpg'),
    },
  ];
}
</script>

<style scoped lang="scss">
@import '../../../variables';

$transition: transform 1.2s ease 0.5s;

@mixin animation {
  transition: $transition;
  &.slide-up {
    transform: translateY(-20px);
  }

  &.slide-down {
    transform: translateY(20px);
  }
}

.playback-recorder {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  text-align: center;
  padding-top: $header-height;

  @media (orientation: portrait) {
    padding-top: $header-height-portrait;
    padding-bottom: 5vh;
  }
}
.playback-recorder-title {
  max-width: 920px;

  h3 {
    @include animation;
  }
}
.playback-recorder-ribbon {
  position: relative;
}
.playback-recorder-phone {
  transform: translateX(-50%) translateY(-30px);
  position: absolute;
  perspective: 400px;
  top: 0;
  left: 50%;
  z-index: 1;
  @include animation;

  &.slide-up {
    transform: translateX(-50%) translateY(-70px);
  }
  &.slide-down {
    transform: translateX(-50%) translateY(30px);
  }
}
.playback-recorder-phone-image {
  width: 500px;
  @media #{$screen-xs-max} {
    width: 220px;
  }
}
.playback-recorder-images {
  perspective: 400px;
  display: flex;
}
.playback-recorder-image {
  transform-style: preserve-3d;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  text-align: left;

  img {
    height: 205px;
    transform: rotateX(38deg);
    opacity: 0.6;

    @media #{$screen-xs-max} {
      height: 90px;
    }

    &.blur {
      filter: blur(15px);
    }
  }
}

.playback-recorder-names {
  display: flex;
  justify-content: center;
  @include animation;
}

.playback-recorder-name {
  width: 425px;
  text-align: center;
  opacity: 0.4;
  @media #{$screen-xs-max} {
    width: 150px;
  }

  &.current {
    opacity: 1;
  }

  .title {
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.8px;
    text-align: center;
    margin-bottom: 16px;

    @media #{$screen-xs-max} {
      font-size: 7px;
      letter-spacing: 0.75px;
      margin-bottom: 2px;
    }

    @media #{$screen-sm-only} and (orientation: landscape) {
      font-size: 14px;
      letter-spacing: 0.75px;
      margin-bottom: 7px;
    }
  }

  .time {
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.4px;
    text-align: center;

    @media #{$screen-xs-max} {
      font-size: 6px;
      letter-spacing: 0.6px;
    }

    @media #{$screen-sm-only} and (orientation: landscape) {
      font-size: 12px;
    }
  }
}
</style>
