<template>
  <div class="three-devices">
    <div class="three-devices-video-container">
      <video muted="muted" playsinline autoplay class="three-devices-video">
        <source type="video/mp4" src="../../../assets/video/video_only.mp4" />
      </video>
      <img
        class="three-devices-frames"
        src="../../../assets/images/devices.png"
        alt=""
      />
    </div>
    <div class="three-devices-content">
      <div class="three-devices-content-inner">
        <h2 class="h1">Один аккаунт&nbsp;&mdash; до&nbsp;пяти устройств</h2>
        <h3 class="h3">
          Смотри любимые фильмы и&nbsp;передачи дома на&nbsp;большом экране,
          по&nbsp;пути на&nbsp;работу&nbsp;&mdash; на&nbsp;смартфоне
          и&nbsp;планшете или на&nbsp;ноутбуке в&nbsp;путешествии!
        </h3>
        <div class="three-devices-bottom">
          <Stores class="three-devices-stores" />
          <button
            type="button"
            class="link three-devices-list-link"
            v-on:click="onShowModal()"
          >
            СПИСОК ПОДДЕРЖИВАЕМЫХ УСТРОЙСТВ
          </button>
        </div>
      </div>
    </div>
    <Modal :visible="showModal" v-on:close="showModal = false">
      <SupportedDevices />
    </Modal>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import Stores from '@/components/Stores.vue';
import Modal from '@/components/Modal.vue';
import SupportedDevices from '@/components/Screens/ThreeDevices/SupportedDevices.vue';
import { dataLayerPush } from '@/services/GoogleAnalyticsService';

@Component({ components: { Modal, Stores, SupportedDevices } })
export default class ScreenThreeDevices extends Vue {
  showModal: boolean = false;

  onShowModal() {
    this.showModal = true;
    dataLayerPush('device_list_click');
  }
}
</script>

<style scoped lang="scss">
@import '../../../variables';

.three-devices {
  height: 100%;
}

.three-devices-video-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.three-devices-video {
  width: 100%;
  @media (orientation: portrait) {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.three-devices-frames {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  @media (orientation: portrait) {
    left: auto;
    top: auto;
    bottom: 0;
    right: 0;
  }
}

.three-devices-content {
  max-width: 1584px;
  margin: 0 auto;
  height: 100%;
  padding-left: $side-padding;
  @media #{$screen-xs-max} {
    padding-left: $side-padding-xs;
  }
}

.three-devices-content-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: 100%;
  h2,
  h3 {
    max-width: 760px;
  }

  @media (orientation: portrait) {
    justify-content: flex-start;
    padding-top: $header-height-portrait;
    width: 80%;
  }

  @media #{$screen-xs-max} {
    padding-top: $header-height-xs;
    width: auto;
  }
}

.three-devices-bottom {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: $side-padding;
  left: 0;
  line-height: 20px;
  @media (orientation: portrait) {
    position: static;
    margin-top: 30px;
  }

  @media #{$screen-xs-max} {
    bottom: $side-padding-xs;
  }
}

.three-devices-stores {
  @media (orientation: portrait) {
    display: none;
  }
}

.three-devices-list-link {
  line-height: 1.5;
  border-left: 1px solid #fff;
  padding-left: 20px;
  margin-left: 20px;
  @media (orientation: portrait) {
    border: 0;
    margin: 0;
    padding: 0;
  }
}
</style>
