<template>
  <div class="modal" :class="{ visible }">
    <button class="close" v-on:click="$emit('close')">
      <span class="close-icon">+</span>
    </button>
    <div class="content" ref="modalContent">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component
export default class Modal extends Vue {
  @Prop({ required: false })
  visible?: boolean;

  @Watch('visible')
  onVisibleChange() {
    this.$store.commit(this.visible ? 'freezeScroll' : 'releaseScroll');
    (this.$refs.modalContent as Element).scrollTop = 0;
  }
}
</script>

<style scoped lang="scss">
@import '../variables';

.modal {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  z-index: $modal-z-index;

  &.visible {
    display: block;
  }

  .close {
    position: absolute;
    cursor: pointer;
    top: $side-padding;
    right: $side-padding;
    width: 32px;
    height: 32px;
    border: 2px solid #fff;
    border-radius: 50%;
    background: transparent;
    transform: rotate(45deg);
    font-size: 22px;
    line-height: 1;
    padding: 0;
    text-align: center;
    touch-action: manipulation;
    user-select: none;

    @media #{$screen-xs-max} {
      top: $side-padding-xs;
      right: $side-padding-xs;
    }
  }
  .close-icon {
    position: absolute;
    top: 2px;
    left: 8px;
  }

  .content {
    overflow: auto;
    height: 100%;
    padding: 120px 96px 0;
    @media #{$screen-sm-only} {
      padding-left: 64px;
      padding-right: 64px;
    }
    @media #{$screen-xs-max} {
      padding-left: 32px;
      padding-right: 32px;
    }
  }
}
</style>
