<template>
  <div class="background" :class="{ blur, [`screen-${screen}`]: true }"></div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class Background extends Vue {
  @Prop({ required: false })
  blur?: boolean;

  @Prop({ required: false })
  screen?: number;
}
</script>

<style scoped lang="scss">
.background {
  position: absolute;
  opacity: 0.3;
  left: 0;
  width: 100vw;
  height: 120%;
  transition: all 1s ease;
  background: radial-gradient(closest-side, #a38066 0, black);
  top: -50%;
  &.blur {
    opacity: 0.8;
  }
  &.screen-0,
  &.screen-1 {
    background: radial-gradient(closest-side, #91ae9c 0%, #a38066 35%, black);
    height: 90%;
    top: 20%;
    opacity: 1;
  }
}
</style>
