import Vue from 'vue';
import Vuex from 'vuex';

import {
  getChannels,
  getContentBlocks,
  getFiles,
  getOffers,
  getTranslations,
  showcaseChannels,
} from './api';
import { LANDING_TYPE } from '@/constants';

Vue.use(Vuex);

interface State {
  scroll: {
    frozen: boolean;
  };
  channels: Array<any>;
  offers: { [key: string]: any };
  translations: Array<any>;
  files: Array<any>;
  loaded: boolean;
  contentBlocks: { [key: string]: any };
}

export default new Vuex.Store({
  state: <State>{
    scroll: {
      frozen: false,
    },
    channels: [],
    offers: {},
    translations: [],
    files: [],
    loaded: false,
    contentBlocks: [],
  },
  mutations: {
    freezeScroll(state) {
      state.scroll.frozen = true;
    },
    releaseScroll(state) {
      state.scroll.frozen = false;
    },
    toggleLoading(state) {
      state.loaded = !state.loaded;
    },
    setTranslations(state, payload) {
      state.translations = payload.translations;
    },
    setFiles(state, payload) {
      state.files = payload.files;
    },
    setChannels(state, payload) {
      state.channels = payload.channels;
    },
    setOffers(state, payload) {
      state.offers = payload.offers;
    },
    setContentBlocks(state, payload) {
      state.contentBlocks = payload.contentBlocks;
    },
  },
  actions: {
    toggleLoading({ commit }) {
      commit('toggleLoading');
    },
    async loadTranslations({ commit }) {
      const { data } = await getTranslations();

      commit('setTranslations', { translations: data.translations });
    },
    async loadFiles({ commit }) {
      const { data } = await getFiles();

      commit('setFiles', { files: data.files });
    },
    async loadChannels({ commit }) {
      const { data } = await getChannels();

      commit('setChannels', { channels: data.channels });
    },
    async loadOffers({ commit }) {
      const { data } = await getOffers();
      const offers = data.siteConfig.wlSpecials[LANDING_TYPE];
      for (const key in offers) {
        if (offers.hasOwnProperty(key) && key !== 'defaultActive') {
          for (const offer of offers[key]) {
            if (offer.id) {
              const channels =
                (await showcaseChannels(offer.id)).data.channels || [];

              offer.content = channels.map(
                (channel: { [key: string]: string }) => channel.channelId
              );
            }
          }
        }
      }

      commit('setOffers', { offers });
    },
    async loadContentBlocks({ commit }) {
      const { data } = await getContentBlocks();

      commit('setContentBlocks', { contentBlocks: data.contentBlocks });
    },
  },
});
