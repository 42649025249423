<template>
  <div class="tv" :class="{ blur }">
    <div
      class="tv-text"
      :class="{ 'showing-text': showText, aside: moveAside }"
    >
      <h2 class="h1">Управляй эфиром</h2>
      <h3 class="h3">
        Пропустил момент фильма? Со&nbsp;Смотрёшкой можно легко перемотать эфир
        и&nbsp;посмотреть, что&nbsp;было.
      </h3>
    </div>
    <svg
      viewBox="0 0 944 182"
      class="tv-progress-bar"
      :class="{ visible: !playVideo && !showVideoCover }"
      preserveAspectRatio="none"
    >
      <defs>
        <filter
          id="rewind-on-tv-filter"
          width="120.2%"
          height="592.5%"
          x="-10.1%"
          y="-246.2%"
          filterUnits="objectBoundingBox"
        >
          <feGaussianBlur in="SourceGraphic" stdDeviation="29.55" />
        </filter>
        <linearGradient
          id="rewind-on-tv-gradient"
          x1="98.161%"
          x2="0%"
          y1="50%"
          y2="50%"
        >
          <stop offset="0%" stop-color="#FFCF00" stop-opacity=".574" />
          <stop offset="100%" stop-color="#FFCF00" stop-opacity="0" />
        </linearGradient>
      </defs>
      <rect
        fill="#FFCF00"
        fill-opacity=".57"
        filter="url(#rewind-on-tv-filter)"
        transform="translate(0 73)"
        width="100%"
        height="36px"
      />
      <rect
        fill="url(#rewind-on-tv-gradient)"
        transform="translate(0 73)"
        width="100%"
        height="8px"
        y="14px"
      />
    </svg>
    <div
      class="tv-playback"
      :class="{ 'showing-text': showText, aside: moveAside }"
    >
      <div class="tv-playback-inner" :class="{ blur }">
        <div class="tv-playback-screen">
          <video
            muted="muted"
            loop="loop"
            playsinline
            ref="video"
            class="tv-video"
            poster="../../../assets/images/rewind/tv-cover.jpg"
          >
            <source type="video/mp4" src="../../../assets/video/video-2.mp4" />
            <source
              type='video/webm; codecs="vp8, vorbis"'
              src="../../../assets/video/video-2.webm"
            />
          </video>
          <img
            src="../../../assets/images/rewind/tv-cover.jpg"
            alt=""
            class="tv-video-cover"
          />
          <img
            src="../../../assets/images/rewind/tv-control.png"
            class="tv-control"
            alt="tv-control"
            :class="{ visible: !playVideo && !showVideoCover }"
          />
        </div>
        <img
          src="../../../assets/images/rewind/tv-frame.png"
          alt=""
          class="tv-frame"
        />
      </div>
    </div>
    <div class="tv-bg-bottom-black-rectangle" :class="{ visible: blur }"></div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import Thumbnail from '@/components/Screens/Thumbnails/Thumbnail.vue';

@Component({ components: { Thumbnail } })
export default class ScreenRewindOnTv extends Vue {
  @Prop({ required: false })
  blur?: boolean;

  @Prop({ required: false })
  moveAside?: boolean;

  @Prop({ required: false })
  showText?: boolean;

  @Prop({ required: false })
  playVideo?: boolean;

  @Prop({ required: false })
  showVideoCover?: boolean;

  async mounted() {
    await this.actualizeVideoPlay();
  }

  @Watch('playVideo')
  async actualizeVideoPlay() {
    const videoRef = this.$refs.video as HTMLVideoElement;
    if (this.playVideo) {
      await videoRef.play();
    } else {
      videoRef.pause();
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../variables';

.tv {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 2s ease;

  &.blur {
    // filter: blur(12px);
  }
}

.tv-bg-bottom-black-rectangle {
  position: absolute;
  height: 20vh;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.9) 85%,
    rgba(0, 0, 0, 0) 100%
  );
  bottom: 0;
  width: 100%;
  opacity: 0;
  transform: translateY(100%);
  transition: all 1s ease;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
}

.tv-text {
  padding-top: $header-height;
  // color: #ffffff;
  padding-left: $side-padding;
  max-width: 45%;
  transform: translateX(-200%);
  transition: transform 1s ease;

  @media (orientation: portrait) {
    max-width: none;
    padding-top: $header-height-portrait;
  }

  @media #{$screen-xs-max} {
    padding-top: $header-height-xs;
    padding-left: $side-padding-xs;
  }

  &.showing-text {
    transform: translateX(0);
  }

  &.aside {
    transform: translateX(200%);
  }
}

.tv-playback {
  height: 75vh;
  position: absolute;
  top: 20vh;
  transform: translateX(-50%);
  left: 50%;
  width: 120vh;
  transition: transform 1s ease;

  @media (orientation: portrait) {
    top: auto;
    bottom: 20vw;
    width: 90vw;
    height: 57vw;
  }

  @media #{$screen-xs-max} and (orientation: portrait) {
    bottom: 10vh;
  }

  &.showing-text {
    transform: translateX(0);
    @media (orientation: portrait) {
      transform: translateX(-15%);
    }
  }

  &.aside {
    transform: translateX(15%);
  }
}

.tv-playback-inner {
  transition: transform 1s ease;

  &.blur {
    transform: scale(0.8);
  }
}

.tv-playback-screen {
  position: absolute;
  left: 1%;
  right: 1%;
  top: 1%;
  bottom: 8%;
  overflow: hidden;
}

.tv-video-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;
  &.visible {
    opacity: 1;
  }
}

.tv-frame {
  width: 100%;
  height: 100%;
  position: relative;
}

.tv-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.tv-control {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  transform: translateY(100%);
  transition: transform 0.5s ease;

  &.visible {
    transform: translateY(0);
  }
}

.tv-progress-bar {
  width: 100vw;
  height: 12vw;
  position: absolute;
  bottom: 12%;
  left: 0;
  overflow: visible;
  transform: translateX(200%);
  transition: transform 1s ease;

  @media (orientation: portrait) {
    bottom: 20%;
  }

  @media #{$screen-xs-max} and (orientation: portrait) {
    bottom: 13vh;
  }

  &.visible {
    transform: translateX(0);
  }
}
</style>
