<template>
  <header
    class="header"
    :class="{
      minimal,
      'fade-in': fadeIn,
      'fade-out': fadeOut,
      'slide-up': slideUp,
      'slide-down': slideDown,
    }"
  >
    <a class="logo" :href="homeUrL">
      <img src="../assets/images/logo.svg" alt="" />
    </a>
    <div class="buttons">
      <Button
        :noBg="true"
        :link="regUrl"
        size="l"
        class="visible-xs-max"
        v-if="screen > 1"
        v-on:click.native="dataLayerPush('reg_button', 'header')"
      >
        Регистрация
      </Button>
      <Button
        :noBg="true"
        :link="loginUrl"
        size="l"
        :class="{ 'visible-xs-min': screen > 1 }"
        v-on:click.native="dataLayerPush('enter_button', 'header')"
      >
        Войти
      </Button>
      <Button
        size="l"
        class="free-30days visible-xs-min"
        :link="regUrl"
        v-on:click.native="dataLayerPush('reg_button', 'header')"
      >
        30 дней бесплатно<sup>*</sup>
      </Button>
    </div>
  </header>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import Button from '@/components/Ui/Button.vue';
import { Prop } from 'vue-property-decorator';
import { URL } from '@/constants';
import { dataLayerPush } from '@/services/GoogleAnalyticsService';

@Component({ components: { Button } })
export default class Header extends Vue {
  @Prop({ required: false })
  minimal?: boolean;

  @Prop({ required: false })
  fadeIn?: boolean;

  @Prop({ required: false })
  fadeOut?: boolean;

  @Prop({ required: false })
  slideUp?: boolean;

  @Prop({ required: false })
  slideDown?: boolean;

  @Prop({ required: true })
  screen!: number;

  regUrl = URL.regHeader;
  loginUrl = URL.login;
  homeUrL = URL.home;
  dataLayerPush = dataLayerPush;
}
</script>

<style scoped lang="scss">
@import '../variables';

.header {
  position: absolute;
  z-index: $header-z-index;
  width: 100%;
  top: 0;
  left: 0;
  transition: transform 0.5s linear, opacity 0.3s linear;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $side-padding $side-padding 20px;
  transform: translateY(0);
  opacity: 1;

  @media #{$screen-xs-max} {
    padding: $side-padding-xs $side-padding-xs 20px;
  }

  &.fade-out {
    opacity: 0;
  }

  &.fade-in {
    opacity: 1;
  }

  &.slide-up {
    transform: translateY(-100%);
  }

  &.slide-down {
    transform: translateY(0);
  }

  &.minimal {
    flex-direction: row-reverse;
    .logo,
    .free-30days {
      display: none;
    }
  }
}

.buttons {
  display: flex;
  align-items: center;
}

.free-30days {
  margin-left: 40px;
}

.logo {
  display: block;
  background: none;
  border: 0;
  img {
    display: block;
    width: 160px;

    @media #{$screen-xs-max} {
      width: 120px;
    }
  }
}
</style>
