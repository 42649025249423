<template>
  <div class="cards">
    <h2 class="h2">{{ title }}</h2>
    <div class="list">
      <div class="list-inner desktop">
        <Card
          v-for="(card, index) in cards"
          :key="card.id + index"
          :active="currentIndex === index"
          :title="getRuTranslation(card.translationId)"
          :image="getImageUrl(card.imageId)"
          :price="card.price"
          :description="getDescription(card)"
        />
      </div>
      <div
        class="list-inner mobile"
        :class="`active${currentIndex}`"
        ref="scroll"
        :style="{ transform: `translateX(-${currentIndex * 68}vw)` }"
      >
        <Card
          v-for="(card, index) in cards"
          :key="card.id + index"
          :active="currentIndex === index"
          :title="getRuTranslation(card.translationId)"
          :image="getImageUrl(card.imageId)"
          :price="card.price"
          :description="getDescription(card)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Hammer from 'hammerjs';
import Card from '../../Ui/Card.vue';
import Component from 'vue-class-component';
import { State } from 'vuex-class';
import { Prop } from 'vue-property-decorator';
import { TChannel, TOffer, TPackage } from '@/api';

@Component({ components: { Card } })
export default class ScreenCardsList extends Vue {
  currentIndex: number = 0;

  @Prop({ required: true })
  title!: string;

  @Prop({ required: true })
  cards!: Array<TPackage>;

  @Prop({ required: true })
  getImageUrl!: Function;

  @Prop({ required: true })
  getRuTranslation!: Function;

  @State('channels')
  channels!: Array<TChannel>;

  mounted() {
    const hammer = new Hammer(this.$refs.scroll as HTMLElement);
    hammer.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL });
    hammer.on('swipeleft swiperight', this.onScroll);
  }

  onScroll(e: HammerInput) {
    if (e.direction === Hammer.DIRECTION_LEFT) {
      this.currentIndex += 1;
    } else if (e.direction === Hammer.DIRECTION_RIGHT) {
      this.currentIndex -= 1;
    }
    if (this.currentIndex < 0) {
      this.currentIndex = this.cards.length - 1;
    }
    if (this.currentIndex > this.cards.length - 1) {
      this.currentIndex = 0;
    }
  }

  getDescription(offer: TOffer) {
    const content: Array<string> = [];

    if (offer.content) {
      offer.content.forEach((channelId) => {
        const result = this.channels.find(
          (channel) => channel.id === channelId
        );

        if (result) content.push(result.info.metaInfo.title.slice(4));
      });
    }
    return content.join(', ');
  }
}
</script>

<style scoped lang="scss">
@import '../../../variables';

.cards {
  max-width: 1296px;
  margin: 0 auto;
  overflow: hidden;
  padding-top: $header-height;

  @media (orientation: portrait) {
    padding-top: $header-height-portrait;
  }

  @media #{$screen-xs-max} {
    padding-top: $header-height-xs;
  }

  h2 {
    text-align: center;
  }

  .list-inner {
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    transition: transform 0.3s ease;

    @media #{$screen-xs-max} {
      flex-wrap: nowrap;
      justify-content: flex-start;
      min-height: 220px;
    }

    &.desktop {
      display: flex;
      align-items: stretch;

      @media #{$screen-xs-max} {
        display: none;
      }
    }

    &.mobile {
      display: none;
      @media #{$screen-xs-max} {
        display: flex;
      }
    }
  }
}
</style>
