<template>
  <div id="app" class="app" ref="app">
    <Header
      :screen="screen"
      :fade-in="screen === 0"
      :fade-out="screen >= 1"
      :minimal="true"
    />
    <Header :screen="screen" :slide-up="screen <= 2" :slide-down="screen > 2" />
    <Background :blur="screen < 2" :screen="screen" />
    <Screen :slide-up="screen > 5">
      <ScreenIntro :visible="screen === 0" />
      <ScreenThumbnails :blur="screen === 0" :visible="screen < 2" />
      <ScreenRewindOnTv
        :show-video-cover="screen === 0"
        :blur="screen < 2"
        :show-text="screen >= 3"
        :play-video="screen > 0 && screen < 4"
        :move-aside="screen >= 5"
      />
      <ScreenRewindOnLaptop :visible="screen >= 5" />
    </Screen>
    <Screen :slide-down="screen < 6" :slide-up="screen > 6">
      <ScreenPlaybackRecorded :slide-down="screen < 6" :slide-up="screen > 6" />
    </Screen>
    <Screen :slide-down="screen < 7" :slide-up="screen > 7">
      <ScreenThreeDevices />
    </Screen>
    <Screen
      :slide-down="screen < 8"
      :slide-up="screen > 8"
      :scrollable="!lastScreenFrozen"
      v-on:scroll="onLastScreenScroll"
    >
      <Loader v-if="!loaded" />
      <template v-else>
        <ScreenPricePlans
          v-if="offers.channelPackages && offers.channelPackages.length"
          :getRuTranslation="getRuTranslation"
        />
        <ScreenCardsList
          v-if="offers.additionalPackages && offers.additionalPackages.length"
          :title="
            TYPE === 'promo' ? 'Дополнительные тарифы' : 'Дополнительные пакеты'
          "
          :cards="offers.additionalPackages"
          :getImageUrl="getImageUrl"
          :getRuTranslation="getRuTranslation"
        />
        <ScreenCardsList
          v-if="offers.vodPackages && offers.vodPackages.length"
          title="Кинотеатры"
          :cards="offers.vodPackages"
          :getImageUrl="getImageUrl"
          :getRuTranslation="getRuTranslation"
        />
      </template>
      <Footer />
    </Screen>
    <Navigation
      :screen="screen"
      onChangeScreen="onChangeScreen"
      v-on:changeScreen="setScreen"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, State } from 'vuex-class';
import Hammer from 'hammerjs';
import { SEQUOIA_BASE_URL } from '@/constants';
import Loader from '@/components/Ui/Loader.vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Navigation from '@/components/Navigation.vue';
import Screen from '@/components/Screen.vue';
import Background from '@/components/Background.vue';
import ScreenIntro from '@/components/Screens/Intro/ScreenIntro.vue';
import ScreenThumbnails from '@/components/Screens/Thumbnails/ScreenThumbnails.vue';
import ScreenRewindOnTv from '@/components/Screens/RewindOnTv/ScreenRewindOnTv.vue';
import ScreenRewindOnLaptop from '@/components/Screens/RewindOnLaptop/ScreenRewindOnLaptop.vue';
import ScreenPlaybackRecorded from '@/components/Screens/PlaybackRecorded/ScreenPlaybackRecorded.vue';
import ScreenThreeDevices from '@/components/Screens/ThreeDevices/ScreenThreeDevices.vue';
import ScreenPricePlans from '@/components/Screens/PricePlans/ScreenPricePlans.vue';
import ScreenCardsList from '@/components/Screens/CardsList/ScreenCardsList.vue';
import { TFile, TOffers, TTranslation } from '@/api';
import LethargyService from '@/services/LethargyService';
import { dataLayerPush } from '@/services/GoogleAnalyticsService';

@Component({
  components: {
    Background,
    Loader,
    Header,
    Footer,
    Navigation,
    Screen,
    ScreenIntro,
    ScreenRewindOnTv,
    ScreenRewindOnLaptop,
    ScreenThumbnails,
    ScreenPlaybackRecorded,
    ScreenThreeDevices,
    ScreenPricePlans,
    ScreenCardsList,
  },
})
export default class App extends Vue {
  screen: number = 0;
  screensAmount: number = 9;
  scrollDirection: number = Hammer.DIRECTION_NONE;
  lastScreenFrozen: boolean = false;
  autoScrolls = [
    {
      scrollDirection: Hammer.DIRECTION_DOWN,
      from: 1,
      to: 3,
      pauseEachScreen: 300,
    },
    {
      scrollDirection: Hammer.DIRECTION_UP,
      from: 2,
      to: 0,
      pauseEachScreen: 300,
    },
    {
      scrollDirection: Hammer.DIRECTION_DOWN,
      from: 4,
      to: 5,
      pauseEachScreen: 1200,
    },
    {
      scrollDirection: Hammer.DIRECTION_UP,
      from: 4,
      to: 3,
      pauseEachScreen: 1200,
    },
  ];
  freezeTimeoutId?: number;
  autoScrollIntervalId?: number;
  lethargy = new LethargyService();
  TYPE = TYPE;

  @State('loaded')
  loaded!: boolean;

  @State('offers')
  offers!: TOffers;

  @State('files')
  files!: Array<TFile>;

  @State('translations')
  translations!: Array<TTranslation>;

  @Action('loadChannels')
  loadChannels!: Function;

  @Action('loadOffers')
  loadOffers!: Function;

  @Action('toggleLoading')
  toggleLoading!: Function;

  @Action('loadTranslations')
  loadTranslations!: Function;

  @Action('loadFiles')
  loadFiles!: Function;

  async mounted() {
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual'; // https://stackoverflow.com/a/48387790/2393499
    }
    this.initScroll();
    this.utmParamsToLocalStorage();
    await this.loadChannels();
    await this.loadOffers();
    await this.loadTranslations();
    await this.loadFiles();
    await this.toggleLoading();
  }

  initScroll() {
    const appRef = this.$refs.app as HTMLElement;
    appRef.addEventListener('wheel', this.onScroll);
    const hammer = new Hammer(appRef);
    hammer.get('swipe').set({ direction: Hammer.DIRECTION_ALL });
    hammer.on('swipeup swipedown', this.onScroll);
  }

  onScroll(e: HammerInput | WheelEvent) {
    const isInertia = this.lethargy.handleEvent(e as WheelEvent) === false;
    if (this.$store.state.scroll.frozen || isInertia) {
      return;
    }
    const deltaY =
      (e as HammerInput).pointerType === 'touch' ? -e.deltaY : e.deltaY; // reverse direction on swipe
    this.scrollDirection = App.detectScrollDirection(deltaY);
    this.actualizeActiveScreen(deltaY);
    this.checkAutoScroll();
  }

  setScreen(newScreen: number) {
    let screen = newScreen;
    screen = Math.min(screen, this.screensAmount - 1);
    screen = Math.max(screen, 0);
    if (screen === this.screen) {
      return;
    }
    this.screen = screen;
    if (this.freezeTimeoutId) {
      clearTimeout(this.freezeTimeoutId);
    }
    this.$store.commit('freezeScroll');
    if (this.screen === 8) {
      this.lastScreenFrozen = true;
    }
    this.freezeTimeoutId = setTimeout(() => {
      this.freezeTimeoutId = undefined;
      if (this.screen === 8) {
        this.lastScreenFrozen = false;
      }
      this.$store.commit('releaseScroll');
    }, 500);

    this.triggerGtmEvent();
  }

  static detectScrollDirection(deltaY: number) {
    if (deltaY > 0) {
      return Hammer.DIRECTION_DOWN;
    } else if (deltaY < 0) {
      return Hammer.DIRECTION_UP;
    } else {
      return Hammer.DIRECTION_NONE;
    }
  }

  actualizeActiveScreen(deltaY: number) {
    if (deltaY < 0) {
      this.setScreen(this.screen - 1);
    } else if (deltaY > 0) {
      this.setScreen(this.screen + 1);
    }
  }

  checkAutoScroll() {
    const autoScroll = this.autoScrolls.find(
      (condition) =>
        condition.from === this.screen &&
        condition.scrollDirection === this.scrollDirection
    );

    if (autoScroll) {
      this.autoScrollTo(autoScroll.to, autoScroll.pauseEachScreen);
    }
  }

  autoScrollTo(screen: number, delay: number) {
    this.$store.commit('freezeScroll');
    if (this.autoScrollIntervalId) {
      return;
    }
    this.autoScrollIntervalId = setInterval(() => {
      if (screen > this.screen) {
        this.setScreen(this.screen + 1);
      }
      if (screen < this.screen) {
        this.setScreen(this.screen - 1);
      }
      if (this.screen === screen) {
        clearInterval(this.autoScrollIntervalId);
        this.autoScrollIntervalId = undefined;
      }
    }, delay);
  }

  onLastScreenScroll(lastScreenScroll: number) {
    const isCurrentScreenLast = this.screen === this.screensAmount - 1;
    if (isCurrentScreenLast) {
      if (
        lastScreenScroll > 0 &&
        this.scrollDirection === Hammer.DIRECTION_DOWN
      ) {
        this.$store.commit('freezeScroll');
      } else {
        this.$store.commit('releaseScroll');
        this.actualizeActiveScreen(-1);
      }
    }
  }

  getImageUrl(key: string) {
    const image = this.files.find((f) => f.key === key);
    const files = image ? image.files : {};

    return files.ru ? `${SEQUOIA_BASE_URL}/${files.ru.url}` : '';
  }

  getRuTranslation(key: string) {
    const transition = this.translations.find((t) => t.key === key);
    const translations = transition ? transition.translations : {};

    return translations.ru ? translations.ru : `{${key}}`;
  }

  triggerGtmEvent() {
    let eventLabel;
    if (this.screen === 3) {
      eventLabel = '2_manage_tv';
    } else if (this.screen === 5) {
      eventLabel = '3_devices';
    } else if (this.screen === 6) {
      eventLabel = '4_archive';
    } else if (this.screen === 7) {
      eventLabel = '5_one_account_5_devices';
    } else if (this.screen === 8) {
      eventLabel = '6_prices';
    }
    if (eventLabel) {
      dataLayerPush('scroll', eventLabel);
    }
  }

  utmParamsToLocalStorage() {
    if (localStorage.getItem('utmParameters')) {
      // if there are some utm-params in local-storage - no need to overwrite them
      return;
    }
    const searchParams = new URLSearchParams(document.location.search);
    const utmParamsKeys: { [key: string]: string } = {
      utmSource: 'utm_source',
      utmMedium: 'utm_medium',
      utmCampaign: 'utm_campaign',
      utmContent: 'utm_content',
      utmTerm: 'utm_term',
    };
    const utmParams: { [key: string]: string } = {};
    for (const key in utmParamsKeys) {
      utmParams[key] = searchParams.get(utmParamsKeys[key]) || '';
    }
    if (Object.values(utmParams).find((value) => !!value)) {
      // there should be at least one utm-param
      localStorage.setItem('utmParameters', JSON.stringify(utmParams));
    }
  }
}
</script>

<style lang="scss">
@import './normalize';
@import './fonts';
@import './variables';

body {
  overflow: hidden;
  margin: 0;
  font-family: $default-font;
  text-rendering: optimizeLegibility;
  background-color: $black-color;
  line-height: 20px;
  font-size: 14px;
  color: $white-color;
}

* {
  box-sizing: border-box;
  outline: 0 !important;
  margin: 0;
  padding: 0;

  &:focus,
  &:active {
    outline: 0;
  }
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.h1 {
  color: $yellow-color;
  font-size: 64px;
  font-weight: 900;
  letter-spacing: 1.38px;
  line-height: 1.2;
  margin: 0 0 24px;
  @media #{$screen-md-only} {
    font-size: 60px;
    line-height: 1;
  }
  @media #{$screen-sm-only} {
    font-size: 50px;
    margin-bottom: 30px;
  }

  @media (max-width: 1023px) and (orientation: portrait) {
    font-size: 50px;
    margin-bottom: 30px;
  }
  @media #{$screen-sm-only} and (orientation: landscape) {
    font-size: 35px;
    margin-bottom: 30px;
  }
  @media #{$screen-xs-max} {
    font-size: 22px;
    letter-spacing: 0.47px;
    line-height: 1.2;
    margin-bottom: 15px;
  }

  &.modal-title {
    color: #fff;
    font-size: 72px;
    line-height: 1;
    font-weight: 900;
    letter-spacing: 4.09px;
    text-transform: uppercase;
    margin: 0 0 128px;
    @media #{$screen-xs-max} {
      font-size: 28px;
      margin-bottom: 64px;
    }
  }
}

.h2 {
  font-size: 36px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 2.15px;
  margin: 0 0 60px;
  text-transform: uppercase;

  @media #{$screen-xs-max} {
    font-size: 22px;
  }
}

.h3 {
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 1.22px;
  line-height: 48px;

  @media #{$screen-md-only} {
    font-size: 30px;
    line-height: 46px;
  }
  @media #{$screen-sm-only} and (orientation: landscape) {
    font-size: 18.2px;
    line-height: 1.6;
  }
  @media (max-width: 1023px) and (orientation: portrait) {
    font-size: 26px;
    line-height: 40px;
  }
  @media #{$screen-xs-max} {
    font-size: 18px;
    letter-spacing: 0.69px;
    line-height: 24px;
  }
}

.app {
  height: 100vh;
}

.link {
  background: none;
  border: 0;
  color: $yellow-color;
  transition: opacity 0.3s ease;
  &:hover {
    opacity: 0.7;
  }
}

.visible-xs-max {
  @media #{$screen-xs-min} {
    display: none !important;
  }
}
.visible-xs-min {
  @media #{$screen-xs-max} {
    display: none !important;
  }
}
.visible-sm-max {
  @media #{$screen-sm-min} {
    display: none !important;
  }
}
.visible-sm-min {
  @media #{$screen-sm-max} {
    display: none !important;
  }
}
</style>
