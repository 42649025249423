<template>
  <div class="next-screen">
    <div class="mouse">
      <div class="icon-bg"><div class="mouse"></div></div>
    </div>
    <div class="arrow"></div>
    <div class="arrow2"></div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class NextScreenIcon extends Vue {}
</script>

<style scoped lang="scss">
@import '../../../variables';

.next-screen {
  margin-bottom: 50px;
  @media #{$screen-sm-max} {
    margin-bottom: 30px;
  }
  .mouse {
    position: relative;
    width: 24px;
    height: 40px;
    border-radius: 13px;
    border: 2px solid rgba(#ffffff, 0.5);
    margin: 0 auto;
    animation-name: MouseMove;
    animation-duration: 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-delay: 2s;
    @media #{$screen-sm-max} {
      display: none;
    }
    &:before {
      display: block;
      content: '';
      position: absolute;
      top: 8px;
      left: calc(50% - 2px);
      width: 4px;
      height: 4px;
      background-color: #ffffff;
      border-radius: 50%;
      animation-name: MouseScrollMove;
      animation-duration: 4s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-delay: 2s;
    }
  }
  .icon-bg {
    display: block;
    position: absolute;
    width: 28px;
    top: -2px;
    left: -4px;
    height: 0;
    overflow: hidden;
    animation-name: MouseBgMove;
    animation-duration: 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-delay: 2s;
    .mouse {
      border: 2px solid #ffffff;
      animation: none;
      &:before {
        display: none;
      }
    }
  }

  .arrow,
  .arrow2 {
    display: block;
    width: 10px;
    height: 5px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0;
    animation-duration: 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-delay: 2s;
    background-image: url(../../../assets/images/arrow.svg);
    margin: 0 auto;
  }

  .arrow {
    margin-top: 5px;
    animation-name: MouseArrowMove;
  }
  .arrow2 {
    margin-top: 2px;
    animation-name: MouseArrowMove2;
  }
}

.icon-bg {
  display: block;
  position: absolute;
  width: 28px;
  top: -2px;
  left: -4px;
  height: 0;
  overflow: hidden;
  animation-name: MouseBgMove;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-delay: 2s;
  .mouse {
    border: 2px solid #fff;
    animation: none;
  }
}

@keyframes MouseMove {
  0% {
    transform: translateY(0);
  }

  20% {
    transform: translateY(4px);
  }

  24% {
    transform: translateY(4px);
  }

  38% {
    transform: translateY(0);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes MouseScrollMove {
  0% {
    opacity: 0.5;
    transform: translateY(0) scale(1);
  }

  20% {
    transform: translateY(18px) scale(2);
    opacity: 1;
  }

  24% {
    transform: translateY(18px) scale(2);
    opacity: 1;
  }

  28% {
    transform: translateY(18px) scale(2);
    opacity: 0.5;
  }

  32% {
    transform: translateY(18px) scale(2);
    opacity: 0;
  }

  33% {
    opacity: 0;
    transform: translateY(18px) scale(2);
  }

  38% {
    opacity: 0;
    transform: translateY(0) scale(1);
  }

  40% {
    opacity: 0.5;
    transform: translateY(0) scale(1);
  }

  to {
    opacity: 0.5;
    transform: translateY(0) scale(1);
  }
}

@keyframes MouseMove {
  0% {
    transform: translateY(0);
  }

  20% {
    transform: translateY(4px);
  }

  24% {
    transform: translateY(4px);
  }

  38% {
    transform: translateY(0);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes MouseArrowMove {
  0% {
    opacity: 0;
    transform: translateY(0);
  }

  10% {
    opacity: 0;
    transform: translateY(0);
  }

  20% {
    opacity: 0.5;
  }

  24% {
    opacity: 1;
    transform: translateY(4px);
  }

  30% {
    opacity: 1;
    transform: translateY(4px);
  }

  34% {
    opacity: 0;
    transform: translateY(4px);
  }

  38% {
    opacity: 0;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(0);
  }
}

@keyframes MouseArrowMove2 {
  0% {
    opacity: 0;
    transform: translateY(0);
  }

  10% {
    opacity: 0;
    transform: translateY(0);
  }

  20% {
    opacity: 0.25;
  }

  24% {
    opacity: 1;
    transform: translateY(4px);
  }

  30% {
    opacity: 1;
    transform: translateY(4px);
  }

  34% {
    opacity: 0;
    transform: translateY(4px);
  }

  38% {
    opacity: 0;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(0);
  }
}

@keyframes MouseBgMove {
  0% {
    height: 0;
  }

  20% {
    height: 44px;
  }

  24% {
    height: 44px;
  }

  28% {
    height: 44px;
    opacity: 0.5;
  }

  32% {
    height: 44px;
    opacity: 0;
  }

  33% {
    opacity: 0;
    height: 0;
  }

  38% {
    opacity: 1;
    height: 0;
  }

  to {
    height: 0;
  }
}
</style>
