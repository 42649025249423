<template>
  <div class="supported-devices">
    <div v-for="(group, groupIndex) in groups" :key="groupIndex">
      <h2 class="h1 modal-title">{{ group.name }}</h2>
      <div
        class="subgroup"
        v-for="subgroup in group.subgroups"
        :key="subgroup.name"
      >
        <div class="subgroup-info">
          <component :is="subgroup.icon" class="subgroup-icon"></component>
          <div class="subgroup-name">{{ subgroup.name }}</div>
        </div>
        <div class="devices-list">
          <div
            class="device"
            v-for="device in subgroup.devices"
            :key="device.name"
          >
            <div class="device-name">{{ device.name }}</div>
            <div class="device-description">
              {{ device.description || device.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import StbIcon from '@/assets/svg/devices/stb.svg';
import TvIcon from '@/assets/svg/devices/tv.svg';
import PcIcon from '@/assets/svg/devices/pc.svg';
import PhoneIcon from '@/assets/svg/devices/phone.svg';
import TabletIcon from '@/assets/svg/devices/tablet.svg';

@Component
export default class SupportedDevices extends Vue {
  groups = [
    {
      name: 'СМОТРИ ДОМА',
      subgroups: [
        {
          name: 'ТВ-ПРИСТАВКИ',
          icon: StbIcon,
          devices: [
            {
              name: 'Смотрёшка Box',
              description: 'Собственная приставка',
            },
            {
              name: 'Android STB',
              description: 'Android 4.2 и выше',
            },
            {
              name: 'Dune HD',
              description:
                'TV-101/101W, TV-102/102С/102С-T2/102W/102W-T2, TV-301/301W',
            },
            {
              name: 'Eltex',
              description: 'Eltex NV-102',
            },
            {
              name: 'MAG',
              description: 'MAG 245, MAG 250, MAG 275',
            },
            {
              name: 'TVIP',
              description: 'TVIP S-605',
            },
          ],
        },
        {
          name: 'SMART TV',
          icon: TvIcon,
          devices: [
            {
              name: 'Samsung Smart TV',
              description: 'Модели 2013 г. и новее',
            },
            {
              name: 'LG Smart TV',
              description: 'Модели 2013 г. и новее',
            },
            {
              name: 'Android TV',
              description: 'Sony Android TV, Philips Android TV',
            },
          ],
        },
        {
          name: 'КОМПЬЮТЕРЫ',
          icon: PcIcon,
          devices: [
            {
              name: 'Safari',
              description: '12 версии и выше',
            },
            {
              name: 'Google Chrome',
              description: '73 версии и выше',
            },
            {
              name: 'Opera',
              description: '58 версии и выше',
            },
            {
              name: 'Mozilla Firefox',
              description: '66 версии и выше',
            },
            {
              name: 'Яндекс Браузер',
              description: '19 версии и выше',
            },
          ],
        },
      ],
    },
    {
      name: 'СМОТРИ В ДОРОГЕ',
      subgroups: [
        {
          name: 'СМАРТФОНЫ',
          icon: PhoneIcon,
          devices: [
            {
              name: 'Apple iPhone',
              description: 'iOS 8.0 и выше',
            },
            {
              name: 'Смартфоны Android',
              description: 'Android 4.2 и выше',
            },
          ],
        },
        {
          name: 'ПЛАНШЕТЫ',
          icon: TabletIcon,
          devices: [
            {
              name: 'Apple iPad',
              description: 'iOS 8.0 и выше',
            },
            {
              name: 'Планшеты Android',
              description: 'Android 4.2 и выше',
            },
          ],
        },
      ],
    },
  ];
}
</script>

<style scoped lang="scss">
@import '../../../variables';

.supported-devices {
  color: #fff;

  .subgroup {
    display: flex;
    margin-bottom: 60px;

    @media #{$screen-md-only} {
      flex-direction: column;
      justify-content: flex-start;
    }

    @media (orientation: portrait) {
      flex-direction: column;
      justify-content: flex-start;
    }

    .subgroup-name {
      font-size: 38px;
      line-height: 1;
      font-weight: 900;
      letter-spacing: 3.09px;
      text-transform: uppercase;
      margin-bottom: 5px;

      @media #{$screen-xs-max} {
        font-size: 22px;
      }
    }

    .subgroup-info {
      color: #ffdb4d;
      width: 45%;

      @media (orientation: portrait) {
        width: 100%;
        margin-bottom: 32px;
      }

      @media #{$screen-md-only} {
        width: 100%;
        margin-bottom: 32px;
      }
    }

    .subgroup-icon {
      width: 64px;
      height: 64px;

      @media #{$screen-xs-max} {
        width: 48px;
        height: 48px;
      }
    }
  }

  .devices-list {
    display: flex;
    flex-wrap: wrap;
    width: 55%;

    @media #{$screen-md-only} {
      width: 100%;
    }

    @media (orientation: portrait) {
      width: 100%;
    }

    .device {
      width: 33.3%;
      margin-bottom: 60px;
      padding-right: 48px;

      @media #{$screen-xs-max} {
        width: 50%;
        margin-bottom: 24px;
      }

      .device-name {
        display: block;
        font-size: 32px;
        font-weight: 900;
        line-height: 1.1;
        letter-spacing: 1.8px;
        margin-bottom: 20px;

        @media #{$screen-xs-max} {
          font-size: 16px;
        }
      }

      .device-description {
        font-size: 22px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 1.2px;

        @media #{$screen-xs-max} {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
