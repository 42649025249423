import { render, staticRenderFns } from "./PricePlan.vue?vue&type=template&id=d433e992&scoped=true"
import script from "./PricePlan.vue?vue&type=script&lang=ts"
export * from "./PricePlan.vue?vue&type=script&lang=ts"
import style0 from "./PricePlan.vue?vue&type=style&index=0&id=d433e992&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d433e992",
  null
  
)

export default component.exports