<template>
  <div class="card" :class="{ active }">
    <div class="top">
      <img class="image" :src="image" alt="" v-if="image" />
      <h3 class="h3 title">{{ title }}</h3>
    </div>
    <div v-if="price" class="price">
      <Price :value="price" units="руб/мес" />
    </div>
    <div v-if="description.length" class="description">
      {{ description }}
    </div>
  </div>
</template>

<script>
import Price from '@/components/Ui/Price';

export default {
  components: { Price },
  props: {
    image: String,
    title: String,
    price: String,
    description: String,
    active: Boolean,
  },
};
</script>

<style scoped lang="scss">
@import '../../variables';

.card {
  width: 292px;
  max-height: 214px;
  border-radius: 8px;
  background-color: #fff;
  padding: 24px;
  text-align: left;
  margin: 0 16px 32px;
  color: #000;
  transition: all 0.3s ease;
  overflow: hidden;

  @media #{$screen-xs-max} {
    align-items: center;
    flex: 1 0 auto;
    margin: 0 4vw;
    width: 60vw;
  }

  &.active {
    @media #{$screen-xs-max} {
      box-shadow: 0 2px 20px rgba(255, 219, 77, 0.4);
      background-color: #ffdb4d;
      height: 214px;
      margin: 0 4vw;
      width: 75vw;
      z-index: 1;
    }
  }

  &:first-child {
    @media #{$screen-xs-max} {
      margin-left: 12.5vw;
    }
  }

  .top {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  .image {
    height: 48px;
    display: block;
    margin-right: 14px;
  }

  .title {
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
  }

  .description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .price {
    margin-bottom: 11px;
  }
}
</style>
