var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"screen",staticClass:"screen",class:{
    'slide-left': _vm.slideLeft,
    'slide-right': _vm.slideRight,
    'slide-up': _vm.slideUp,
    'slide-down': _vm.slideDown,
    scrollable: _vm.scrollable,
  },on:{"scroll":function($event){return _vm.$emit('scroll', _vm.$refs.screen.scrollTop)}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }