<template>
  <a
    v-if="link"
    :href="link"
    class="button"
    v-bind:class="{ 'no-bg': noBg, glow, ['size-' + size]: true }"
  >
    <slot />
  </a>
  <button
    v-else
    type="button"
    class="button"
    v-bind:class="{ 'no-bg': noBg, glow, ['size-' + size]: true }"
  >
    <slot />
  </button>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class Button extends Vue {
  @Prop({ required: false })
  link?: boolean;

  @Prop({ required: false })
  glow?: boolean;

  @Prop({ required: false, default: 'm' })
  size?: string;

  @Prop({ required: false })
  noBg?: boolean;
}
</script>

<style scoped lang="scss">
@import '../../variables';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 215px;
  height: 48px;
  line-height: 48px;
  border: 0;
  border-radius: 8px;
  background-color: $yellow-color;
  color: $black-color;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1.35px;
  font-family: $default-font;
  transition: 0.3s ease background-color;

  &:hover {
    background-color: $dark-yellow-color;
  }

  &.size-l {
    font-size: 16px;
    width: 298px;
    height: 72px;
    line-height: 72px;
    letter-spacing: 1.8px;
  }

  &.no-bg {
    width: auto;
    height: 72px;
    color: $yellow-color;
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 1.8px;
    background-color: transparent;
    transition: 0.3s ease color;

    &:hover {
      color: $dark-yellow-color;
    }
  }

  &.glow {
    box-shadow: 0 12px 25px rgba(255, 219, 77, 0.4);
    transition: 0.3s ease box-shadow;
    &:hover {
      background-color: $yellow-color;
      box-shadow: 0 12px 32px rgba(255, 219, 77, 0.6);
    }
  }
}
</style>
